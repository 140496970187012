@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap');


/* *p {
  font-family: arial black, arial-w01-black, arial-w02-black, arial-w10 black,
    sans-serif;
} */

.learnMoreCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  padding-top: 0;
}

a {
  text-decoration: none;
  color: #e1872d;
}

.subText {
  font-family: "Didact Gothic";
  margin-bottom: 0;
}

.btn {
  background-color: #102a57;
  font-family: arial;
  border: none;
  text-transform: uppercase;
  color: white;
  transition: .5s;
  margin-bottom: 1.5rem;
  width: 12rem;
}

.btn:hover {
  background-color: #284981;
  border: none;
  color: #ffffff;
  /* transform: scale(80%); */
  box-shadow: none;
}

@media screen and (min-width:375px) {

  .btn {
    padding: 1rem;
  }
}

@media screen and (min-width:768px) {
  
  .btn {
    margin: -1.3rem 0 .5rem 0;
  }

  .subText {
    margin-bottom: 1rem;
  }

}