@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Six+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300;400;500&family=Six+Caps&display=swap');


.divider {
  display: block;
  height: 4rem;
  margin-top: -1.6rem;
  background-image: url("../../images/mobile-about/divider.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: transparent;
}

.spanStyle {
  font-size: 1.15rem;
}

.quoteText {
  margin: .5rem 0;
  color: #000000;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
}

.mainWrapper {
  background: linear-gradient(142deg,
      rgba(255, 255, 255, 2) 47%,
      rgba(255, 255, 255, 0.5206874986322654) 71%,
      rgba(2, 0, 36, 0) 100%);
  padding: 1rem 1rem 0 1rem;
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  margin: -.5rem 0 2rem 0;
  background-color: #ffffff;
  /* background-image: url("../../images/mobile-about/about-background-image.png"); */
  /* background-size: contain; */
  background-blend-mode: color-burn;
}

.cardWrapper {
  background-color: #102a57;
  border-radius: 0;
  border: none;
}

.innerCardWrapper {
  text-align: center;
  border: 0;
}

.title,
.aboutParagraph {
  font-family: "Six Caps";
  font-size: 3.9rem;
  line-height: 4.8rem;
  color: #000000;
}

.title {
  margin: 0;
}

.subTitle {
  font-family: "Oswald";
  margin: 0 0 -.2rem 0;
  font-size: 1.2rem;
}

.aboutParagraph {
  font-size: 1rem;
  line-height: 1.2rem;
  font-family: "Didact Gothic";
  padding: 1rem 0;
  color: #4e5155;
}

.container {
  display: none;
}

@media screen and (min-width:414px) {
  .aboutParagraph {
    padding: 1rem;
  }
}

@media screen and (min-width:600px) {
  .divider {
    margin-top: -1.51rem;
  }

  .aboutParagraph {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }

  .title {
    font-size: 5rem;
    margin: 0 0 1rem 0;
  }
}

@media screen and (min-width:768px) {

  .cardWrapper {
    margin-top: 2rem;
    padding: 0;
  }

  .divider {
    display: none;
  }

  .hrStyle {
    margin: .7rem;
  }

  .quoteText {
    font-size: .9rem;
  }

  .aboutParagraph {
    padding: 0 0 1rem 1.2rem;
    font-size: .99rem;
  }

  .mainWrapper {
    display: flex;
    flex-direction: row;
    background: linear-gradient(142deg, rgba(255, 255, 255, 2) 47%, rgba(255, 255, 255, 118.520687) 71%, rgba(2, 0, 36, 0) 136%);
  }

  .title {
    font-size: 4rem;
    margin-top: -.3rem;
  }

  .titleImgWrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150%;
    margin-top: -1rem;
  }

  .textAndBtnWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 149%;
  }

}

@media screen and (min-width:1022px) {

  .titleImgWrapper {
    width: 150%;
    margin: 1rem;
  }

  .title {
    font-size: 6rem;
    margin: 1.7rem 0 2rem 0;
  }

  .subTitle {
    font-size: 2.2rem;
  }

  .spanStyle {
    font-size: 1.5rem;
  }

  .aboutParagraph {
    padding: 1.5rem;
    font-size: 1.3rem;
  }

  .quoteText {
    font-size: 1.1rem;
  }
}

@media screen and (min-width:1200px) {
  .title {
    font-size: 7rem;
    margin: 2.7rem 0 2rem 0;
  }

  .subTitle {
    font-size: 3.2rem;
  }

  .aboutParagraph{
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .quoteText {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width:1400px) {
  .aboutParagraph{
    padding: 3.2rem;
  }
}
