@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Six+Caps&display=swap');


.email,
.name,
.address,
.address2,
.city,
.state,
.zip,
.textarea {
  padding: 0 0.3rem;
  margin: 0.3rem 0;
}

.footer {
  color: black;
  width: 100%;
  font-size: .8rem;
  text-align: center;
  padding: .5rem 0;
  background-color: #a98519;
}

.error {
  fill: #ffd23b;
  width: 24px;
  height: 21px;
}

.email,
.name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  /* background-color: #232323; */
}

.contactTitle {
  font-size: 4rem;
  font-family: "Six Caps";
  color: black;
  margin-bottom: 3px;
  text-align: center;
}

.contact {
  font-size: 1.22rem;
  color: black;
  margin: 0 0 .9rem 0rem;
}

.message {
  padding: .1rem .5rem;
}


.labelForm {
  margin-top: 1rem;
  margin-bottom: 0;
  color: black;
  text-align: center;
}

.formStyleContact {
  background-size: cover;
  border: none;
}

.formStyle {
  background-size: cover;
  background-color: white;
  border: none;
  text-align: left;
}

.btn {
  background-color: black;
  font-family: arial;
  text-transform: uppercase;
  border: none;
  rotate: 0deg;
  top: 0;
  width: 14rem;
  margin: 1rem 0 .5rem 0;
  color: white;
  transition: .5s;
}

.btn:hover {
  rotate: 0deg;
  color: black;
  background-color: #fdbc5394;
  border: none;
  box-shadow: none;
}

.btnWrapper {
  text-align: center;
}

.footer {
  font-size: .85rem;
  text-align: center;
  color: white;
  background-color: #232323;
  padding: .5rem 0;
}

.divider2 {
  display: block;
  width: 100%;
  height: 28px;
  background-image: url("../../images/mobile-events/divider-white.png");
  background-size: 107%;
  background-position: center;
  position: relative;
  background-color: transparent;
  top: 5px;
  z-index: 1;
}

.optionsWrapper {
  text-align: left;
}

@media screen and (min-width: 373px) {
  .contact {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 428px) {

  .divider2{
    height: 39px;
  }
}

@media screen and (min-width: 600px) {
  .contact {
    font-size: 1.5rem;
  }

  .contactTitle {
    font-size: 6rem;
  }

  .contactBottom {
    font-size: 2rem;
  }

  .divider2 {
    height: 45px;
  }
}

@media screen and (min-width: 765px) {

  .btnWrapper {
    text-align: left;
  }

  .contactTextWrapper em,
  .contactTextWrapper p {
    font-size: 1.12rem;
    line-height: 1.122rem;
  }
}

@media screen and (min-width: 1022px) {
  .divider2 {
    display: none;
  }

  .contactTextWrapper {
    margin: 0;
  }

  .contactTitle {
    font-size: 10rem;
  }

}