@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap');


/* .carousel .slide img {
    width: 200%;
} */

.carousel .slider {
    z-index: 0 !important;
}

.card {
    background-color: transparent !important;
}



.react-multiple-carousel__arrow {
    background-color: transparent !important;

}

.carousel.carousel-slider .control-arrow {
    padding: 75px !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background-color: transparent !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid transparent !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid transparent !important;
}


.nav-link {
    color: white !important
}

.navbar-toggler {
    background-color: #34003d;
}

.navbar-toggler-icon {
    filter: invert(1);
}

.card {
    --bs-card-bg: #ffffffe0;
}

.form-check {
    padding-left: 1.5rem !important;
}

.form-check input {
    padding: .7rem;
    border-bottom: 1px solid black;
}

.form-check input:hover {
    background-color: #000000;
}

.form-check-input:checked {
    background-color: #2b4c85;
    border-color: #2b4c85;
}

.form-check label {
    margin: -.1rem 0 .5rem 1rem;
}

.react-dropdown-select {
    background-color: white !important;
}

/* @media screen and (width: 1024px) {
    .carousel-root{
    width: 80% !important;
}
} */